import React from 'react'

import { Link } from 'gatsby'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'

const WebinarsPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Webinars con información sobre CRM para WhatsApp"
                description="Información sobre todos los webinars que ofrece Optiwe con datos sobre como utiliar un CRM para WhatsApp con el objetivo de incrementar las ventas y mejorar la atención al cliente"
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/recursos/webinars/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <strong>Historial de webinars</strong> con toda la información sobre
                                como utilizar Optiwe como tu CRM para WhatsApp
                            </h1>
                            <p className="ow-section--cards__desc">
                                - 10 de Febrero 2023: {' '}
                                <Link to="/es/recursos/webinars/febrero-2023-con-red-7/">
                                    Webinar en colaboración con Red-7
                                </Link>
                            </p>
                            <p className="ow-section--cards__desc">
                                - 28 de Abril 2023: {' '}
                                <Link to="/es/recursos/webinars/abril-2023-con-gvamax/">
                                    Webinar en colaboración con GVAMax
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default WebinarsPage
